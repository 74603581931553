import React, {useRef, useState} from 'react';
import pstore from "../assets/images/img01.jpeg";
import arleft from "../assets/icons/arrow-left-rounded-black.png";
import arright from "../assets/icons/arrow-right-rounded-black.png";
import {getPersonResult} from "../utils/CalcPower";
import CustomSelect from "./customSelect";
import clsx from "clsx";
import female from "../assets/images/form/female.jpg";
import male from "../assets/images/form/male.jpg";
import genderworkout from "../assets/images/form/gender-workout.jpg";
import family from "../assets/images/form/family.jpg";
import measure from "../assets/images/form/measure.jpg";
import weightimg from "../assets/images/form/weight.png";
import pullupsimg from "../assets/images/form/pullups.png";

import m1 from "../assets/images/form/bmi/m1.png";
import m2 from "../assets/images/form/bmi/m2.png";
import m3 from "../assets/images/form/bmi/m3.png";
import m4 from "../assets/images/form/bmi/m4.png";
import m5 from "../assets/images/form/bmi/m5.png";
import f1 from "../assets/images/form/bmi/f1.png";
import f2 from "../assets/images/form/bmi/f2.png";
import f3 from "../assets/images/form/bmi/f3.png";
import f4 from "../assets/images/form/bmi/f4.png";
import f5 from "../assets/images/form/bmi/f5.png";
import logowht from "../assets/images/logowht2.png";
import logored from "../assets/images/logored.png";
import logogold from "../assets/images/logogold.png";
import bgabs from "../assets/images/bgabs001.jpg";
import badge11 from "../assets/images/form/badges/badge11.png";
import badge12 from "../assets/images/form/badges/badge12.png";
import badge13 from "../assets/images/form/badges/badge13.png";
import badge14 from "../assets/images/form/badges/badge14.png";
import badge15 from "../assets/images/form/badges/badge15.png";
import badge16 from "../assets/images/form/badges/badge16.png";
import badge17 from "../assets/images/form/badges/badge17.png";
import badge18 from "../assets/images/form/badges/badge18.png";
import * as htmlToImage from "html-to-image";

function InfoForm(props) {
    const [name, setName] = useState('');
    const [email, setemail] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState(null);
    const [height, setHeight] = useState(null);
    const [weight, setWeight] = useState(null);
    const [bodyFat, setBodyFat] = useState(null);
    const [pushups, setPushups] = useState(null);
    const [pullups, setPullups] = useState(null);
    const [result, setResult] = useState({});

    const cardRef = useRef(null);

    const handleExportToPng = () => {
        if (cardRef.current) {
            htmlToImage.toPng(cardRef.current)
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = 'fizo-fit-result.png';
                    link.href = dataUrl;
                    link.click();
                })
                .catch((error) => {
                    console.error('oops, something went wrong!', error);
                });
        }
    };

    const [currentIndex, setCurrentIndex] = useState(0);
    const imgs = [
        genderworkout,
        family,
        measure,
        weightimg,
        genderworkout,
        pullupsimg,
    ];
    const bmi = {
        male: [m1, m2, m3, m4, m5],
        female: [f1, f2, f3, f4, f5]
    }
    const badges = [badge11, badge12, badge13, badge14, badge15, badge16, badge17, badge18]
    const feedbacks = [
        "You're getting started! Keep it up!",
        "You're making progress. Keep pushing!",
        "You're on your way to improvement!",
        "You're doing well. Keep going!",
        "You have above average power. Great job!",
        "You have high power. Keep pushing your limits!",
        "You have very high power. You're amazing!",
        "You have elite power. You're a superstar!"
    ];
    const notes = [
        "Starting Strong : You're just beginning your fitness journey. Keep up the great work, and you'll see incredible progress! 10% of people around the world have a score like yours.",
        "You're making steady progress and moving in the right direction. Keep pushing yourself to reach new heights! Approximately 15% of people globally share a score similar to yours.",
        "You're well on your way to achieving your fitness goals. Stay consistent, and you'll see amazing results! Around 20% of individuals worldwide have a score like yours.",
        "You're performing well and making positive changes in your fitness journey. Keep up the good work! You're ahead of about 45% of the global population. Roughly 25% of people globally share a similar score.",
        "Your power is above average, and you're doing an excellent job. Keep challenging yourself to reach even greater heights! You're ahead of about 70% of the global population. Around 20% of individuals worldwide have a score like yours.",
        "You possess high power levels. Continue pushing your limits and setting new goals – you're on the path to greatness! Your score surpasses that of approximately 90% of the world. Only 5% of people around the world have a score like yours.",
        "Your power levels are exceptional. You're doing amazing things for your fitness. Keep up the incredible work! You're stronger than 94% of people worldwide! only 4% of people around the world have a score like yours.",
        "You've reached elite status with your power levels. You're a superstar in the world of fitness. Keep shining and inspiring others! You're stronger than 99% of people worldwide! You're among the top 1% of the fittest individuals globally!",
    ];


    const handlePrev = () => {
        setCurrentIndex(currentIndex - 1)
    }
    const handleNext = () => {
        setCurrentIndex(currentIndex + 1)
        if (currentIndex === 5) calcPersonResult();
    }
    const calcPersonResult = () => {
        const per = {
            name,
            gender,
            age: Number(age),
            weight: Number(weight),
            height: Number(height),
            pushups: Number(pushups),
            pullups: Number(pullups),
            bf: Number(bodyFat),
        };
        setResult(getPersonResult(per))
        console.log(getPersonResult(per))
    }
    return (
        <div id="assessment" className="bg-gray-100">
            <div className="container py-16 px-2 lg:px-4">
                <h3 className="font-bold text-4xl text-gray-600 rtitle mb-4">Discover Your Strength.</h3>
                <p>
                    Delve into a personalized assessment that unveils your unique fitness signature. Our specialized
                    scoring system offers a comprehensive view of your strength and endurance. Not only can you
                    understand where you stand and grasp your potential, but you can also see how you measure up against
                    others around the world. Find out which fitness category you align with and take pride in every
                    milestone.<br/><span className="font-bold text-xl">Remember, it's not just about figures; it's a reflection of your dedication and your journey in fitness.</span>
                </p>


                <div className="grid lg:grid-cols-2 gap-4 mt-4">
                    { currentIndex < 6 && <div className="p-2 flex items-center">
                        <img src={imgs[currentIndex]} alt="form img" className="mx-auto rounded-xl shadow-xl"/>
                    </div>}

                    <div className="p-2 grid gap-2 items-center">
                        {/*<h5 className="font-bold">how old are you ?</h5>*/}
                        <div className="">
                            {currentIndex === 0 &&
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="w-full col-span-2">
                                        <h5 className="font-bold">What's your name?</h5>
                                        {/*<label htmlFor="name">What is your name ?</label>*/}
                                        <input id="name" type="text" placeholder="Jhon Doe..."
                                               className="w-full h-14 p-2 rounded-lg border-3 border-gray-400 hover:border-green-500 focus:border-green-600 shadow-md"
                                               value={name}
                                               onChange={(e) => {
                                                   setName(e.target.value)
                                               }}
                                        />
                                        <input id="name" type="email" placeholder="jhondoe@email.com..."
                                               className="w-full h-14 p-2 rounded-lg border-3 border-gray-400 hover:border-green-500 focus:border-green-600 shadow-md mt-2"
                                               value={email}
                                               onChange={(e) => {
                                                   setemail(e.target.value)
                                               }}
                                        />
                                    </div>
                                    <h5 className="font-bold col-span-2">What's your gender?</h5>
                                    <div onClick={() => {
                                        setGender("male")
                                    }}
                                         className={clsx("text-center cursor-pointer p-2 border-3 border-gray-200 hover:border-green-600 rounded-xl w-fit shadow-md", gender === "male" && "border-green-600")}>
                                        <img src={male} alt="form img" className="mx-auto rounded-xl"/>
                                        <h5 className="font-bold text-gray-600 mt-2">Male</h5>
                                    </div>
                                    <div onClick={() => {
                                        setGender("female")
                                    }}
                                         className={clsx("text-center cursor-pointer p-2 border-3 border-gray-200 hover:border-green-600 rounded-xl w-fit shadow-md", gender === "female" && "border-green-600")}>
                                        <img src={female} alt="form img" className="mx-auto rounded-xl"/>
                                        <h5 className="font-bold text-gray-600 mt-2">Female</h5>
                                    </div>
                                </div>
                            }
                            {currentIndex === 1 &&
                                <div>
                                    <h5 className="font-bold">How old are you?</h5>
                                    <CustomSelect
                                        options={["5 - 10", "11 - 16", "17 - 28", "29 - 48", "49 - 63", "64+"]}
                                        value={age}
                                        setValue={e => {
                                            setAge(e);
                                            // handleNext();
                                        }}/>
                                </div>}

                            {currentIndex === 2 && <div className="w-full col-span-2">
                                <h5 className="font-bold">What's your height? (m)</h5>
                                {/*<label htmlFor="name">What is your name ?</label>*/}
                                <input id="name" type="number" placeholder="in meters..."
                                       max={2.5}
                                       min={1.2}
                                       className="w-full h-14 p-2 rounded-lg border-2 border-gray-200 shadow-md hover:border-green-500 focus:border-green-600"
                                       value={height}
                                       onChange={(e) => {
                                           setHeight(e.target.value)
                                       }}
                                />
                                <h5 className="font-bold mt-4">How much do you weigh? (kg)</h5>
                                {/*<label htmlFor="name">What is your name ?</label>*/}
                                <input id="name" type="number" placeholder="in kilograms..."
                                       className="w-full h-14 p-2 rounded-lg border-2 border-gray-200 shadow-md hover:border-green-500 focus:border-green-600"
                                       value={weight}
                                       onChange={(e) => {
                                           setWeight(e.target.value)
                                       }}
                                />
                            </div>}

                            {currentIndex === 3 && <div className="w-full col-span-2">
                                <h5 className="font-bold">What's your Body look like (BMI)?</h5>
                                <div className="flex">
                                    <div onClick={() => {
                                        setBodyFat(1)
                                    }}
                                         className={clsx("text-center cursor-pointer p-2 border-3 border-gray-200 hover:border-green-600 rounded-xl w-fit shadow-md", bodyFat === 1 && "border-green-600")}>
                                        <img src={gender ? bmi[`${gender}`][0] : bmi['male'][0]} alt="form img"
                                             className="mx-auto rounded-xl"/>
                                        <h5 className="font-bold text-gray-600 mt-2">Very Lean</h5>
                                    </div>
                                    <div onClick={() => {
                                        setBodyFat(2)
                                    }}
                                         className={clsx("text-center cursor-pointer p-2 border-3 border-gray-200 hover:border-green-600 rounded-xl w-fit shadow-md", bodyFat === 2 && "border-green-600")}>
                                        <img src={gender ? bmi[`${gender}`][1] : bmi['male'][1]} alt="form img"
                                             className="mx-auto rounded-xl"/>
                                        <h5 className="font-bold text-gray-600 mt-2">Athletic</h5>
                                    </div>
                                    <div onClick={() => {
                                        setBodyFat(3)
                                    }}
                                         className={clsx("text-center cursor-pointer p-2 border-3 border-gray-200 hover:border-green-600 rounded-xl w-fit shadow-md", bodyFat === 3 && "border-green-600")}>
                                        <img src={gender ? bmi[`${gender}`][2] : bmi['male'][2]} alt="form img"
                                             className="mx-auto rounded-xl"/>
                                        <h5 className="font-bold text-gray-600 mt-2">Average</h5>
                                    </div>
                                    <div onClick={() => {
                                        setBodyFat(4)
                                    }}
                                         className={clsx("text-center cursor-pointer p-2 border-3 border-gray-200 hover:border-green-600 rounded-xl w-fit shadow-md", bodyFat === 4 && "border-green-600")}>
                                        <img src={gender ? bmi[`${gender}`][3] : bmi['male'][3]} alt="form img"
                                             className="mx-auto rounded-xl"/>
                                        <h5 className="font-bold text-gray-600 mt-2">Over weight</h5>
                                    </div>
                                    <div onClick={() => {
                                        setBodyFat(5)
                                    }}
                                         className={clsx("text-center cursor-pointer p-2 border-3 border-gray-200 hover:border-green-600 rounded-xl w-fit shadow-md", bodyFat === 5 && "border-green-600")}>
                                        <img src={gender ? bmi[`${gender}`][4] : bmi['male'][4]} alt="form img"
                                             className="mx-auto rounded-xl"/>
                                        <h5 className="font-bold text-gray-600 mt-2">Obese</h5>
                                    </div>


                                </div>

                            </div>}

                            {currentIndex === 4 &&
                                <div>
                                    <h5 className="font-bold">How many pushups can you do consecutively without a
                                        break?</h5>
                                    <CustomSelect options={["0 - 5", "6 - 15", "16 - 30", "31 - 50", "51+"]}
                                                  value={pushups}
                                                  setValue={e => {
                                                      setPushups(e);
                                                      // handleNext();
                                                  }}/>
                                </div>}
                            {currentIndex === 5 &&
                                <div>
                                    <h5 className="font-bold">How many pullups can you do consecutively without a
                                        break?</h5>
                                    <CustomSelect options={["0", "1 - 3", "4 - 8", "9 - 15", "16+"]}
                                                  value={pullups}
                                                  setValue={e => {
                                                      setPullups(e);
                                                      // handleNext();
                                                  }}/>
                                </div>}

                            {currentIndex === 6 &&
                                <div ref={cardRef} className="p-3 bg-gray-100">

                                <div
                                    className="p-4 rounded-2xl shadow-md bg-secondary-color text-white relative overflow-hidden z-10 result-card">
                                    <img src={logogold} alt="fizo logo" className="w-20 h-fit"/>
                                    <div className="flex items-center justify-between z-30">
                                        <div>
                                            <h2 className="font-bold text-8xl z-10">{result?.score}</h2>
                                                <h5 className="uppercase font-bold text-2xl ">{result?.person?.name}</h5>

                                        </div>
                                        <div className="text-center">
                                        <img src={badges[Number(result?.result?.index - 1)]} alt="badge" className="w-44 mx-auto"/>
                                            <h2>{result?.result?.badge}</h2>
                                        </div>
                                        {/*<h2 className="font-bold text-8xl z-10 text-white">80</h2>*/}
                                    </div>
                                    <div className="flex justify-between items-center">

                                        {/*<img src={badge15} alt="badge" className="w-44"/>*/}

                                    </div>
                                    <div className="p-2 dark-bg rounded-xl">
                                        <p>{feedbacks[Number(result?.result?.index - 1)]}</p>
                                        <p>{notes[Number(result?.result?.index - 1)]}</p>

                                        <div className="text-center">fizo.fit</div>
                                    </div>
                                </div>

                            </div>}

                            { currentIndex < 6 && <div className="flex justify-between mt-4">
                                <button disabled={currentIndex === 0} onClick={handlePrev}
                                        className="rounded-full border-3 border-gray-400 w-14 h-14 p-2 hover:opacity-50">
                                    <img src={arleft} alt="arrow left"/>
                                </button>
                                <div className="flex items-center">
                                    <span
                                        className={clsx("p-1 rounded-full h-fit", currentIndex === 0 ? "bg-green-600" : "bg-gray-200")}></span>
                                    <span
                                        className={clsx("ml-1 p-1 rounded-full h-fit", currentIndex === 1 ? "bg-green-600" : "bg-gray-200")}></span>
                                    <span
                                        className={clsx("ml-1 p-1 rounded-full h-fit", currentIndex === 2 ? "bg-green-600" : "bg-gray-200")}></span>
                                    <span
                                        className={clsx("ml-1 p-1 rounded-full h-fit", currentIndex === 3 ? "bg-green-600" : "bg-gray-200")}></span>
                                    <span
                                        className={clsx("ml-1 p-1 rounded-full h-fit", currentIndex === 4 ? "bg-green-600" : "bg-gray-200")}></span>
                                    <span
                                        className={clsx("ml-1 p-1 rounded-full h-fit", currentIndex === 5 ? "bg-green-600" : "bg-gray-200")}></span>
                                </div>
                                <button disabled={currentIndex === 6} onClick={handleNext}
                                        className="rounded-full border-3 border-gray-400 w-14 h-14 p-2 hover:opacity-50">
                                    <img src={arright} alt="arrow left"/>
                                </button>
                            </div>}
                        </div>
                    </div>
                    { currentIndex === 6 && <div className="p-2 flex items-center">
                        <div>
                            {result?.score > 70 && <div>
                                <div>
                                    <h3>Share Your Success! 🌟</h3>
                                    <p>You've done amazing work! Share your fitness achievements with friends and inspire
                                        them to join you on your journey. Together, we can reach new heights! 💪👫
                                        #FitnessGoals #Fizo.fit</p>
                                </div>
                            </div> }
                            <div>
                                {result?.score <= 70 && <h3>Boost Your Power! 💪</h3>}
                                    <p>Ready to level up your fitness? Our coaching program can help you reach your goals faster. Don't wait, start today! 🏋️‍♀️</p>
                                <a href="/#appoinment">
                                    <button className="btn">Make an Appointment</button>
                                </a>
                                <button className="btn" onClick={handleExportToPng}>Share with Friends</button>
                            </div>

                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default InfoForm;