import pstore from "../../assets/icons/play-store.svg"
import apple from "../../assets/icons/apple.svg";
import React from "react";
import female from "../../assets/icons/femalewht.png";
import male from "../../assets/icons/malewht.png";
import CountUp from "react-countup";

function Header() {
    return (
        <div className="w-screen h-screen flex items-center bg-primary-color header-bg text-white">
            <div className="container flex items-center justify-between">
                <div className="p-4 max-w-2xl flex-1" >
                    <h1 className="font-bold text-5xl lg:text-7xl htitle mb-4">Elevate Your Fitness Game</h1>
                    <div className="w-fit flex ">
                        <div className=" flex justify-center items-center">
                            <img src={female} alt="isfm" className="w-20 mx-auto"/>
                            <div>
                                <h3 className="font-bold">
                                    <CountUp
                                        start={2000}
                                        end={2690}
                                        duration={1.75}
                                    />
                                </h3>
                                <h5>Female</h5>
                            </div>
                        </div>
                        <div className=" flex justify-center items-center">
                            <img src={male} alt="isfm" className="w-20 mx-auto"/>
                            <div>
                                <h3 className="font-bold">
                                    <CountUp
                                        start={500}
                                        end={1042}
                                        duration={1.75}
                                    /></h3>
                                <h5>Male</h5>
                            </div>
                        </div>
                    </div>
                    <p className="my-4 max-w-lg  text-xl">Step into a world where fitness meets passion.<br/>Join us to access premier workouts and unparalleled coaching.<br/>Every rep, every step, every beat – make it count.</p>

                    <div className="lg:flex ">
                        <a href="https://play.google.com/store/apps/details?id=zone.online.weightlose.weightlose" target="_blank"
                           className=" mt-2 lg:mt-0 transition duration-300 p-2 rounded-xl bg-black flex items-center space-x-3 hover:opacity-75">
                            <img src={pstore} alt="play store icon" className="w-16 mx-2"/>
                            <div className="mx-2">
                                <h4 className="text-gray-200 uppercase text-sm font-bold">Get it on</h4>
                                <h4 className="text-white font-bold">Google Play</h4>
                            </div>
                        </a>
                        <a href="#"
                           className="lg:ml-2 mt-2 lg:mt-0 transition duration-300 p-2 rounded-xl bg-black flex items-center space-x-3 hover:opacity-75">
                            <img src={apple} alt="play store icon" className="w-16 mx-2"/>
                            <div className="mx-2">
                                <h4 className="text-gray-200 uppercase text-sm font-bold">Download on the</h4>
                                <h4 className="text-white font-bold">App Store</h4>
                            </div>
                        </a>
                    </div>
                </div>
                {/*<img src={hbg} className="w-1/3 h-1/3 hidden lg:block" alt="hjk"/>*/}
            </div>
        </div>
    );
}

export default Header;
