import {AGE_SCORE, BI_SCORE, BODYFAT_SCORE, PULLUPS_SCORE, PUSHUPS_SCORE, RESULT_SCORE} from "./Score";
// Age
// Weight
// Height
// Body Indicator
// pushups
// pullups
// Body fat
const getAgeScore = (index) => {
    return Number(AGE_SCORE.find(a => a.index === index).score)
}
const getBIScore = (weight, height) => {
    const bi = Number(weight / (height * height)).toFixed(0)
    // return 70;
    return bi > 0 ? Number(BI_SCORE.find(a => a.min <= bi && bi <= a.max).score) : 7
}
const getPushupsScore = (gender, index) => {
    const data = gender === "male" ? PUSHUPS_SCORE.men : PUSHUPS_SCORE.women
    return Number(data.find(a => a.index === index).score)
}
const getPullupsScore = (gender, index) => {
    const data = gender === "male" ? PULLUPS_SCORE.men : PULLUPS_SCORE.women
    return Number(data.find(a => a.index === index).score)
}
const getBFScore = (gender, index) => {
    const data = gender === "male" ? BODYFAT_SCORE.men : BODYFAT_SCORE.women
    return Number(data.find(a => a.index === index).score)
}

export const getPersonResult = (person) => {
    // const per = {
    //     name: "racgs",
    //     age: 1,
    //     gender: "male",
    //     weight: 70,
    //     height: 1.80,
    //     pushups: 3,
    //     pullups: 2,
    //     bf: 3,
    // };
    const sum1 = getAgeScore(person.age) + getBIScore(person.weight, person.height) + getBFScore(person.gender, person.bf)
    const sum2 = Math.max(getPushupsScore(person.gender, person.pushups), getPullupsScore(person.gender, person.pushups))
    const fscore = Number((sum1 + sum2) / 4) * 10;
    return {
        person: person,
        score: fscore,
        result: RESULT_SCORE.find(r => r.minScore <= fscore && fscore <= r.maxScore)
    };
}