import React from 'react';
import clsx from "clsx";

function CustomSelect({options, setValue, value}) {
    return (

        <ul className="grid gap-2 p-0">
            {options.map((item, index) =>
                <li className={clsx("p-3 rounded-md text-center cursor-pointer hover:bg-green-400 hover:text-white", value === Number(index + 1) ? "bg-green-600 text-white" : "bg-gray-200")} onClick={() => {setValue(Number(index + 1))}}>{item}</li>
            )}
        </ul>
    );
}

export default CustomSelect;