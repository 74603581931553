import React from 'react';

function Terms(props) {
    return (
        <div id="terms" className="container mt-16 py-16 px-2 lg:px-4">
            <div>
                <h2>Health Commitment Statement</h2>
                <p>
                    Your health is your responsibility. Fizo Personal Training is dedicated to helping you take every opportunity to enjoy the facilities that we offer. With this in mind, we have carefully considered what we can reasonably expect of each other.
                </p>
                <p>
                    Fizo Personal Training commitment to you:
                </p>
                <ul>
                    <li>
                        We will respect your personal decisions, and allow you to make your own decisions about what exercise you can carry out. However, we ask you not to exercise beyond what you consider to be your own abilities.
                    </li>
                    <li>
                        We will make every reasonable effort to make sure that our equipment and facilities are in a safe condition for you to use and enjoy.
                    </li>
                    <li>
                        We will take all reasonable steps to make sure our staff are qualified to the fitness industry standards as set out by the Register of Exercise Professionals.
                    </li>
                    <li>
                        If you tell us that you have a disability which puts you at a substantial disadvantage in accessing our equipment and facilities, we will consider what adjustments, if any, are reasonable for us to make.
                    </li>
                </ul>
                <p>
                    Your commitment to Fizo Personal Training:
                </p>
                <ul>
                    <li>
                        You should not exercise beyond your own abilities. If you know or are concerned that you have a medical condition or have been prescribed medication which might interfere with you exercising safely, before you use our equipment and facilities you should get advice from a relevant medical professional and follow that advice.
                    </li>
                    <li>
                        You should make yourself aware of any rules and instructions, including warning notices, and follow them. Exercise carries its own risks. You should not carry out any activities which you have been told are not suitable for you.
                    </li>
                    <li>
                        You should let us know immediately if you feel ill when using our equipment or facilities. Our staff members are not qualified doctors, but there will be a person available who has had first-aid training.
                    </li>
                    <li>
                        If you have a disability, you must follow any reasonable instructions to allow you to exercise safely.
                    </li>
                </ul>
                <h2>Terms & Conditions of Service</h2>
                <h3>1. The Trainer:</h3>
                <p>
                    1.1 The trainer will use their training, skills and knowledge to design a highly personalised, safe and effective programme of exercise for the client that shall take into account the clients’ current health, personal goals, fitness levels as well as exercise likes and dislikes.
                </p>
                <p>
                    1.2 The Trainer will advise and guide the client to achieve mutually agreed exercise/nutrition targets in the clients’ unsupervised periods between personal training sessions.
                </p>
                <p>
                    1.3 The trainer will ensure that they hold suitable public liability insurance for the activities that they will be carrying out at Fizo Fitness.
                </p>
                <p>
                    1.4 The trainer will endeavour to be punctual for the start of a clients’ session and ensure that the client has been recorded in the Acuity scheduling system before training with them.
                </p>
                <h3>
                    2. Fizo Fitness:
                </h3>
                <p>
                    2.1 Fizo Fitness will provide both client and trainer all necessary facility and equipment needed in order for the trainer to deliver effective training sessions and for client to receive likewise.
                </p>
                <p>
                    2.2 Fizo Fitness will ensure that the facility and the provided equipment in the facility is maintained to good standards and is safe for usage for the purpose it is intended.
                </p>
                <p>
                    2.3 Fizo Fitness will provide the facility for Fizo Personal Training to market personal training services to internal and external clients and to match clients with suitable personal trainers.
                </p>
                <h3>
                    3. Fizo Personal Training:
                </h3>
                <p>
                    3.1 Fizo Personal Training will ensure that clients complete all relevant registration forms (whether physical or online) so that their identity is known to Fizo Fitness and to the trainer.
                </p>
                <p>
                    3.2 Fizo Personal Training will ensure that all personal trainers are suitably qualified and have current public liability insurance to carry out the relevant services.
                </p>
                <p>
                    3.3 Fizo Personal Training will provide details of trainer profiles online so that clients may choose the trainer best suited to their needs. Should the client request a recommendation, Fizo Personal Training will be happy to do so.
                </p>
                <p>
                    3.4 Fizo Personal Training will continuously monitor and train the Personal Trainers to ensure that quality standards are met.
                </p>
                <p>
                    3.5 Fizo Personal Training will provide alternative trainers should the client request a change from the trainer allocated and/or propose a replacement or additional trainer if it is identified that the client would benefit from a change.
                </p>
                <p>
                    3.6 Should a trainer be unable to fulfil the remaining sessions of a package for any reason, Fizo Personal Training will allocate and/or propose alternative replacement trainers. If a suitable replacement cannot be agreed with the client, a partial refund will be offered as detailed in Section 8 below.
                </p>
                <p>
                    3.7 Fizo Personal Training will ensure that payments received from the client will be held by Fizo Personal Training pending sessions being completed and that they will forward on payments to the trainer in relation to the sessions completed and the terms agreed herein this agreement by the client.
                </p>

                <h3>
                    4. The Client:
                </h3>
                <p>
                    4.1 All clients MUST indicate that they have read, understood and agree to the Health Commitment Statement before commencing any training or exercise programme.
                </p>
                <p>
                    4.2 The Client is required to provide a supplementary letter of medical clearance from their GP should this be requested by the trainer.
                </p>
                <p>
                    4.3 The client must update their trainer immediately if they are aware of any change in medical condition or negative change in their health at any time that this occurs during their training package.
                </p>
                <p>
                    4.4 The client is aware that they are willingly volunteering to undertake a progressive training program and that they accept the risks, including but not limited to increased heart stress and the chance of musculoskeletal injuries. In volunteering for this program, the client agrees to assume full responsibility for these risks and waive any possibility for personal damage claims against the trainer. It is also fully understood that the training is an agreement between the client and the trainer and that neither Fizo Fitness nor Fizo Personal Training have any part or any liability for any training specific injuries or loss.
                </p>
                <p>
                    4.5 The client warrants that they are not aware of any limiting physical conditions or disability that would preclude an exercise program.
                </p>
                <p>
                    4.6 The client should be over the age of 12 to participate in this program, should a client be under the age of 18 then a parent or guardian must sign full acceptance of the terms and conditions on their behalf.
                </p>

                <h3>
                    5. Cancellation Policy:
                </h3>
                <p>
                    5.1 Where possible 24 hours of notice is required for all cancellations.
                </p>
                <p>
                    5.2 Notice of less than 12 hours will incur the forfeiture of the session with no opportunity of re-use or refund.
                </p>
                <h3>
                    6. Lateness Policy:
                </h3>
                <p>
                    6.1 If the client is late for a session, the session will NOT be extended and will end at the appointed time of finish.
                </p>
                <p>
                    6.2 If the trainer is late, additional time will be added to that session or subsequent sessions.
                </p>
                <h3>
                    7. Fee charging policy:
                </h3>
                <p>
                    7.1 Packages of 12, 24, or 36 sessions are designed to be used over a maximum period of 3 months (90 days) to achieve the optimal results. We understand that on occasion your schedule may be compromised either due to holidays, sickness or other unforeseen absence. In respect of this the maximum length of time that we will extend your package is equal to 30 further days.
                </p>
                <p>
                    7.2 The maximum period of time that a package can be used is 120 days. thereafter any remaining sessions will become null and void with no opportunity of usage or refund unless a further extension is agreed in writing with Fizo Personal Training no later than 30 days prior to expiry.
                </p>
                <h3>
                    8. Refund Policy:
                </h3>
                <p>
                    8.1 In general all monies paid and due under this contract are non refundable under any circumstance except for medical incapacity, relocation, redundancy or a suitable replacement trainer cannot be agreed (see 3.6) as follows:
                </p>
                <p>
                    8.2 In the event that a client provides medical proof that they will be unable to complete all of their sessions of the agreed training package, or they can provide proof of relocation to a distance more than 5 miles from their original residence and 5 miles further away from Fizo Fitness, or that they have been made redundant, or a suitable replacement trainer cannot be agreed then a partial refund policy will be applicable.
                </p>
                <p>
                    8.3 The maximum possible refund is 50% of the contracted package cost in the event that NO sessions have been used and a proportionately lower refund should any actual sessions have taken place. For the avoidance of doubt, if no sessions have been used then a 50% refund will be made.
                </p>
                <p>
                    For example: If only 3 sessions have been used of a package of 12 then 50% is non refundable at signing and the remaining 50% of the fees will be proportioned 3/12 retained and 9/12 refunded. For example a package cost of £600 for 12 sessions. 50% non refundable = £300 the remaining £300 is proportioned £75 retained and £225 Refunded. In accordance with 7.2 above, if in the case a refund is requested at a date after the agreement expiry has already passed (generally 120 days maximum from signing unless agreed otherwise in writing) then all remaining sessions will become null and void with no opportunity for usage, extension or refund regardless of circumstance.
                </p>



            </div>
        </div>
    );
}

export default Terms;