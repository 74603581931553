import right from "../assets/images/right.svg";
import Header from "../components/home/Header";
import InfoForm from "../components/InfoForm";
import About from "../components/home/About";
import Testimonial from "../components/home/Testimonial";
import Appointment from "./Appointment";

function Home() {
  return (
    <>
      <Header/>
      <About/>
        {/*Services*/}
      <InfoForm/>
      <Testimonial/>
      <Appointment/>
    </>
  );
}

export default Home;
