import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./containers/Home";
import Footer from "./components/Footer";
import Plans from "./containers/Plans";
import Appointment from "./containers/Appointment";
import Terms from "./containers/Terms";
import Privacy from "./containers/Privacy";

function App() {
  return (
      <div>
          <Navbar />
              <Routes>
                  <Route index path="/" element={<Home />}/>
                  <Route index path="/terms" element={<Terms />}/>
                  <Route index path="/contact" element={<Appointment />}/>
                  <Route index path="/privacy" element={<Privacy />}/>
              </Routes>
          {/*Subscribe*/}
          {/*Footer*/}
          <Footer />

      </div>

  );
}

export default App;
