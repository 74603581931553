import React from 'react';
import facebook from "../assets/icons/facebook.svg";
import linkedin from "../assets/icons/linkedin.svg";
import youtube from "../assets/icons/youtube.svg";
import instagram from "../assets/icons/instagram.svg";
import logo from "../assets/images/logo.png";
import logowht from "../assets/images/logo.png";
import apple from "../assets/icons/apple.svg";
import pstore from "../assets/icons/play-store.svg";
import {Link} from "react-router-dom";

function Footer(props) {
    return (
        <div className="bg-primary-color mt-10 pt-10">
            <div className="container  px-2 lg:px-4 justify-center text-white position-relative">
                {/*<div*/}
                {/*    className='position-absolute top-0 start-50 translate-middle flex w-10/12 h-48 bg-gray-100 shadow-md px-10 rounded-full'>*/}
                {/*    <div className=' flex text-white  items-center w-1/3'>*/}
                {/*        <h3 className=" rtitle w-full text-center text-black">Join US</h3>*/}
                {/*    </div>*/}
                {/*    <div className="p-2 lg:flex justify-between items-center w-2/3">*/}
                {/*        <div className="flex w-full rounded-full bg-white border-gray-400 border-1 p-2">*/}
                {/*            <input type="email" className="bg-white opacity-75 w-full p-2 rounded-l-full text-black"*/}
                {/*                   placeholder="jdoe@email.com..."/>*/}
                {/*            <button*/}
                {/*                className="px-4 py-3 text-white bg-primary-color rounded-full hover:opacity-75">Subscribe*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*    </div>*/}

                {/*</div>*/}

                <div className="lg:flex justify-between">
                    <div className="">
                        <div className="md:flex">
                            <img
                                className="w-16 h-16 m-2"
                                src={logowht} alt="img"
                            />
                            <div>
                                <p className="my-2">Empowering individuals, one workout at a time.<br/>Join us on the path to a
                                    healthier, stronger you.</p>
                                <p>WhatsApp : <a href="https://api.whatsapp.com/send/?phone=12072807016" className="text-white" target="_blank">+1 207-280-7016</a></p>
                                <p>Email : <a href="mailto:contact@fizo.fit" className="text-white">contact@fizo.fit</a></p>
                            </div>
                        </div>
                        {/*<div>*/}
                        {/*    <div className="flex">*/}
                        {/*        <a href="https://web.facebook.com/YassCoach" target="_blank" rel="noreferrer"><img*/}
                        {/*            src={facebook} alt="fizo facebook"*/}
                        {/*            className="social-icons p-1 rounded-md opacity-80 hover:opacity-100"/></a>*/}
                        {/*        /!*<a href="https://github.com/rhmx01" target="_blank" rel="noreferrer"><img src={linkedin} alt="Rachid hamma ali github profile" className="social-icons p-1 rounded-md opacity-80 hover:opacity-100"/></a>*!/*/}
                        {/*        /!*<a href="https://twitter.com/r_hammaali" target="_blank" rel="noreferrer"><img src={youtube} alt="Rachid hamma ali on twitter" className="social-icons p-1 rounded-md opacity-80 hover:opacity-100"/></a>*!/*/}
                        {/*        <a href="https://www.instagram.com/yas_coach" target="_blank" rel="noreferrer"><img*/}
                        {/*            src={instagram} alt="fizo insta"*/}
                        {/*            className="social-icons p-1 rounded-md opacity-80 hover:opacity-100"/></a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                    <div>
                        <ul>
                            <li className='mt-2'><a className='text-white' href="/#about">About Us</a></li>
                            <li className='mt-1.5'><a className='text-white' href="/#rdv">Contact</a></li>
                            {/*<li className='mt-1.5'><a className='text-white' href="/workouts">Workout</a></li>*/}
                            {/*<li className='mt-1.5'><a className='text-white' href="/plans">Plans</a></li>*/}
                            {/*<li className='mt-1.5'><a className='text-white' href="/partners">Partner ship</a></li>*/}
                            <li className='mt-1.5'><Link className='text-white' to="/terms">Terms & Conditions</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="grid gap-2">
                        <a href="https://play.google.com/store/apps/details?id=zone.online.weightlose.weightlose" target="_blank"
                           className=" mt-2 lg:mt-0 transition duration-300 p-2 rounded-xl bg-black flex items-center space-x-3 hover:opacity-75">
                            <img src={pstore} alt="play store icon" className="w-16 mx-2"/>
                            <div className="mx-2">
                                <h4 className="text-gray-200 uppercase text-sm font-bold">Get it on</h4>
                                <h4 className="text-white font-bold">Google Play</h4>
                            </div>
                        </a>
                        <a href="#"
                           className=" mt-2 lg:mt-0 transition duration-300 p-2 rounded-xl bg-black flex items-center space-x-3 hover:opacity-75">
                            <img src={apple} alt="play store icon" className="w-16 mx-2"/>
                            <div className="mx-2">
                                <h4 className="text-gray-200 uppercase text-sm font-bold">Download on the</h4>
                                <h4 className="text-white font-bold">App Store</h4>
                            </div>
                        </a>
                    </div>
                </div>


            </div>
            <div className="text-center mt-6 text-white pb-10">{new Date().getFullYear()} © fizo.fit. All rights
                reserved.
            </div>
        </div>
    );
}

export default Footer;