import React from 'react';
import review1 from "../../assets/images/reviews/review1.jpg";
import review2 from "../../assets/images/reviews/review2.jpg";
import review3 from "../../assets/images/reviews/review3.png";
import review4 from "../../assets/images/reviews/review4.png";

function Testimonial(props) {
    return (
        <div id="reviews" className="container py-16 px-2 lg:px-4 justify-center">

            <h5 className="text-gray-600 text-center font-bold text-4xl rtitle">Real Stories, Real Results</h5>

            <div className="mt-4">
                <div className="grid lg:grid-cols-3 gap-4">

                    <div className="bg-gray-100 p-4 text-center rounded-md shadow-md max-w-md">
                        <img src={review2} className="rounded-md shadow-md mb-2" alt="fizo reviews"/>
                        <h5>Samuel Thompson</h5>
                        <h5 className="text-sm">5 Months Member</h5>
                        <p className="text-center  font-light">“ At first, I was skeptical about joining another online fitness
                            platform. But from my first interaction with Fizo.fit, I could sense the
                            difference. The program they curated for me considered my hectic work schedule and past
                            injuries. My personal trainer, David, has been outstanding, checking in even on my off days.
                            It's been three months, and I've learned so much about my body and its capabilities. I feel
                            like a valued member, not just another customer. ”</p>
                    </div>

                    <div className="bg-gray-100 p-4 text-center rounded-md shadow-md max-w-md">
                        <img src={review4} className="rounded-md shadow-md mb-2" alt="fizo reviews"/>
                        <h5>Priya Singh</h5>
                        <h5 className="text-sm">3 Months Member</h5>
                        <p className="text-center  font-light">“ Being a new mom, I was looking for an online fitness program that's
                            flexible and understanding of my needs. FIZO Fit nailed it! The custom program they
                            developed focuses on postpartum strength and recovery. My trainer, Sara, is not just
                            knowledgeable but incredibly supportive, making sure I'm comfortable yet challenged in every
                            session. I feel stronger and more confident with each passing day. ”</p>
                    </div>

                    {/*<div className="bg-gray-100 p-4 text-center rounded-md shadow-md max-w-md">*/}
                    {/*    <img src={review3} className="rounded-md shadow-md mb-2" alt="fizo reviews"/>*/}
                    {/*    <h5>Martha Brown</h5>*/}
                    {/*    <h5 className="text-sm">3 Months Member</h5>*/}
                    {/*    <p className="text-center  font-light">“ Nam libero tempore, cum soluta nobis est eligendi optio cumque*/}
                    {/*        nihil*/}
                    {/*        impedit quo minus id quod maxime placeat ”</p>*/}
                    {/*</div>*/}

                    <div className="bg-gray-100 p-4 text-center rounded-md shadow-md max-w-md">
                        <img src={review1} className="rounded-md shadow-md mb-2" alt="fizo reviews"/>
                        <h5>Lina Fernandez</h5>
                        <h5 className="text-sm">2 Months Member</h5>
                        <p className="text-center  font-light">“ I've been a part of many fitness communities, but FIZO
                            stands out. Their custom program was an eye-opener – addressing my strengths and weaknesses
                            in ways I hadn't anticipated. My trainer, Jessica, is more than just a coach; she's a friend
                            who genuinely cares about my wellbeing. The progress I've made in these five months speaks
                            volumes. ”</p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Testimonial;