import React from 'react';
import about from "../../assets/images/about.png";

function About(props) {
    return (
        <div id="about">
            <div className="container py-16 px-2 lg:px-4 justify-center">
                <div className="grid items-center lg:grid-cols-2 gap-4">
                    <div>
                        <h5 className="text-gray-400 font-bold uppercase ">About US</h5>
                        <h3 className="font-bold text-4xl text-gray-600 mb-3 rtitle">Shaping the Future of Fitness & Coaching.</h3>
                        <p className="max-w-xl">
                            At FIZO Fit, we believe that everyone deserves access to world-class fitness training, no matter where they are.<br/>Our platform connects fitness enthusiasts with top-tier trainers, offering customized workouts, coaching sessions, and a community that fuels your passion.<br/>Whether you're looking to shed some pounds, tone up, or even train for a marathon, we're here to guide, motivate, and celebrate your every milestone.
                        </p>
                        <a href="/#rdv">
                        <button className="btn">Join Our Fitness Revolution</button>
                        </a>
                    </div>
                    <div>
                        <img src={about} alt="ds"/>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default About;