import React, {useState} from 'react';
import trainers from "../assets/images/trainers.png";

function Appointment(props) {
    const [name, setName] = useState("");
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");
    // const [name, setName] = useState("");
    return (
        <div id="rdv" className="container py-16 px-2 lg:px-4">

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-center">
                <div className="p-2 flex items-center">
                    <img src={trainers} alt="form img" className="mx-auto rounded-xl shadow-xl"/>
                </div>
                <div className="h-fit  p-2 grid gap-2">
                    <h3 className="font-bold text-4xl text-gray-600 rtitle">Join Our Fitness Revolution.</h3>
                    <p>
                        Only join if you're ready to get in the best shape of your life!
                    </p>
                    <input id="name" type="text" placeholder="Jhon Doe..."
                           className="w-full h-14 p-2 rounded-lg border-3 border-gray-500 shadow-md hover:border-green-500 focus:border-green-600"
                           value={name}
                           onChange={(e) => {
                               setName(e.target.value)
                           }}
                    />
                    <input id="country" type="text" placeholder="Country"
                           className="w-full h-14 p-2 rounded-lg border-3 border-gray-500 shadow-md hover:border-green-500 focus:border-green-600"
                           value={country}
                           onChange={(e) => {
                               setCountry(e.target.value)
                           }}
                    />
                    <input id="email" type="email" placeholder="jhondoe@email.com"
                           className="w-full h-14 p-2 rounded-lg border-3 border-gray-500 shadow-md hover:border-green-500 focus:border-green-600"
                           value={email}
                           onChange={(e) => {
                               setEmail(e.target.value)
                           }}
                    />
                    <input id="phone" type="text" placeholder="+1 234 323-6..."
                           className="w-full h-14 p-2 rounded-lg border-3 border-gray-500 shadow-md hover:border-green-500 focus:border-green-600"
                           value={phone}
                           onChange={(e) => {
                               setPhone(e.target.value)
                           }}
                    />
                    <textarea id="comment"  placeholder="Your message here..."
                              rows={6}
                           className="w-full p-2 rounded-lg border-3 border-gray-500 shadow-md hover:border-green-500 focus:border-green-600"
                           value={comment}
                           onChange={(e) => {
                               setComment(e.target.value)
                           }}
                    />
                    <div className="flex justify-end mt-3">
                        <button className="btn">Send</button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Appointment;