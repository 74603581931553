import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import pstore from "../assets/icons/play-store.svg";
import logowht from "../assets/images/logo.png";
import clsx from "clsx";

function Navbar() {
  const location = useLocation();
  const body = document.querySelector("body");
  const [isNavbarActive, setIsNavbarActive] = useState(false);
  const toggleClass = () => {
    if (body.classList.contains("nav-active"))
      body.classList.remove("nav-active");
    else body.classList.add("nav-active");
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const breakpoint = 760;
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setIsNavbarActive(true);
      } else {
        setIsNavbarActive(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const isSizeSm = windowWidth < breakpoint;
  return (
    <>
      <div
        className={clsx("mnav", (isNavbarActive || location.pathname !== "/") && "active", !isSizeSm && "bg-primary-color")}
      >
        {isSizeSm ? (
          <div className="nav">
            <div className="nav-but-wrap position-absolute start-0 ms-3" style={{marginTop:'30px'}}>
              <div className="menu-icon hover-target" onClick={toggleClass}>
                <span className="menu-icon__line menu-icon__line-left"></span>
                <span className="menu-icon__line"></span>
                <span className="menu-icon__line menu-icon__line-right"></span>
              </div>
            </div>
            <div className="nav__content">
              <ul className="nav__list">
                <li
                  className={`nav__list-item ${
                    location.pathname === "/" ? "active-nav" : ""
                  }`}
                >
                  <a href="/" className="hover-target">
                    Home
                  </a>
                </li>
                <li className="nav__list-item">
                  <a href="/#about" className="hover-target">
                    About Us
                  </a>
                </li>
                <li className="nav__list-item">
                  <a href="/#assessment" className="hover-target">
                    Assessment
                  </a>
                </li>
                <li className="nav__list-item">
                  <a href="/#reviews" className="hover-target">
                    Success Stories
                  </a>
                </li>
                <li className="nav__list-item">
                  <a href="/#rdv" className="hover-target">
                    Get Started
                  </a>
                </li>
              </ul>

            </div>
          </div>
        ) : (
          <div className="container items flex justify-between">
            <ul className=" items-center">
              <li className="link-wrapper mx-3">
                <Link
                  to="/"
                >
                  <img src={logowht} alt="home icone" className="w-16"/>
                </Link>
              </li>

              <li className="link-wrapper mx-3">
                <a className="link text-white hover:border-b-2 hover:pb-2" href="/#about">
                  About Us<span></span>
                </a>
              </li>
              <li className="link-wrapper mx-3">
                <a className="link text-white hover:border-b-2 hover:pb-2" href="/#assessment">
                  Assessment<span></span>
                </a>
              </li>
              <li className="link-wrapper mx-3">
                <a className="link text-white hover:border-b-2 hover:pb-2" href="/#reviews">
                  Success Stories<span></span>
                </a>
              </li>
            </ul>
            <ul>
              <li className="link-wrapper mx-3">
                <a className="link text-white hover:border-b-2 hover:pb-2" href="/#rdv">
                  Get Started<span></span>
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
      <Outlet />
    </>
  );
}

export default Navbar;
