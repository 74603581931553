// Gender
// Age
// Weight
// Height
// Body Indicator
// pushups
// pullups
// Body fat


export const AGE_SCORE = [
    {index: 1, label: "5 - 10", score: 7},
    {index: 2, label: "11 - 16", score: 8},
    {index: 3, label: "17 - 28", score: 10},
    {index: 4, label: "29 - 48", score: 7},
    {index: 5, label: "49 - 63", score: 6},
    {index: 6, label: "64+", score: 5},
];
export const BI_SCORE = [
    {index: 1, min: 6, max: 17, label: "6% - 17%", score: 2},
    {index: 2, min: 18, max: 24, label: "18% - 24%", score: 10},
    {index: 3, min: 25, max: 29, label: "25% - 29%", score: 5},
    {index: 4, min: 30, max: 34, label: "30% - 34%", score: 3},
    {index: 5, min: 35, max: 40, label: "35% - 40%", score: 2},
    {index: 6, min: 41, max: 100, label: "+41%", score: 1},
]

export const PUSHUPS_SCORE = {
    men: [
        {index: 1, label: "1 - 4", score: 1},
        {index: 2, label: "5 - 14", score: 3},
        {index: 3, label: "15 - 29", score: 5},
        {index: 4, label: "30 - 49", score: 8},
        {index: 5, label: "50+", score: 10},
    ],
    women: [
        {index: 1, label: "1 - 4", score: 2},
        {index: 2, label: "5 - 14", score: 4},
        {index: 3, label: "15 - 29", score: 7},
        {index: 4, label: "30 - 49", score: 9},
        {index: 5, label: "50+", score: 10},
    ]
}

export const PULLUPS_SCORE = {
    men: [
        {index: 1, label: "0", score: 1},
        {index: 2, label: "1 - 3", score: 3},
        {index: 3, label: "4 - 8", score: 6},
        {index: 4, label: "9 - 14", score: 9},
        {index: 5, label: "15+", score: 10},
    ],
    women: [
        {index: 1, label: "0", score: 2},
        {index: 2, label: "1 - 3", score: 4},
        {index: 3, label: "4 - 8", score: 7},
        {index: 4, label: "9 - 14", score: 9},
        {index: 5, label: "15+", score: 10},
    ]
}

export const BODYFAT_SCORE = {
    men: [
        {index: 1, cat: "Very Lean" , min: 2, max:6, label: "2 - 6", score: 9},
        {index: 2, cat: "Athletic" , min: 7, max:13, label: "7 - 13", score: 10},
        {index: 3, cat: "Average" , min: 14, max:17, label: "14 - 17", score: 8},
        {index: 4, cat: "Overweight" , min: 18, max:24, label: "18 - 24", score:7},
        {index: 5, cat: "Obese" , min: 25, max:100, label: "25+", score: 6}
    ],
    women: [
        {index: 1, cat: "Very Lean", min: 6, max:14, label: "6 - 14", score: 9},
        {index: 2, cat: "Athletic", min: 15, max:20, label: "15 - 20", score: 10},
        {index: 3, cat: "Average", min: 21, max:24, label: "21 - 24", score: 8},
        {index: 4, cat: "Overweight", min: 25, max:31, label: "25 - 31", score: 7},
        {index: 5, cat: "Obese", min: 32, max:100, label: "32+", score: 6},
    ]
}

export const RESULT_SCORE = [
    {index: 1, category: "Extremely Low Power", badge: "Ultra Novice", minScore: 0, maxScore: 10, count: 10, rate: 0},
    {index: 2, category: "Very Low Power", badge: "Mighty Beginner", minScore: 11, maxScore: 20, count: 15, rate: 10},
    {index: 3, category: "Low Power", badge: "Energetic Rookie", minScore: 21, maxScore: 30, count: 20, rate: 25},
    {index: 4, category: "Below Average Power", badge: "Thunder Cadet", minScore: 31, maxScore: 40, count: 25, rate: 45},
    {index: 5, category: "Above Average Power", badge: "Storm Champion", minScore: 41, maxScore: 60, count: 20, rate: 70},
    {index: 6, category: "High Power", badge: "Super Nova", minScore: 61, maxScore: 75, count: 5, rate: 90},
    {index: 7, category: "Very High Power", badge: "Mega Force", minScore: 76, maxScore: 90, count: 4, rate: 94},
    {index: 8, category: "Elite Power", badge: "Apex Titan", minScore: 91, maxScore: 100, count: 1, rate: 99},
]